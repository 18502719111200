<script setup>
import Header from "@/components/Tools/Header/Header.vue";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import i18n from "@/core/plugins/vue-i18n";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import CustomFunctions from "@/components/CustomFunctions/customFunctions";
import { useRoute, useRouter } from "vue-router/composables";
import { Error } from "@/core/plugins/swal";
import Form from "@/components/CustomFunctions/Form.vue";
import { useWorkNote } from "@/composables/useWorkNote";

const LOADING_KEY = "loadCustomFunctions";
const router = useRouter();
const route = useRoute();
const customFunctionId = computed(() => route?.params?.id);
const toast = getCurrentInstance().proxy.$toast;
const formConfig = {
  label: "",
  enableVariables: false,
  snippetPrefix: "customFunctions",
  enableTypecast: false
};
const headerButtons = computed(() => [
  {
    type: "button",
    title: i18n.t("dataStructures.openEditor"),
    emit: "openEditor"
  },
  {
    type: "button",
    title: i18n.t("general.save"),
    emit: "save"
  }
]);
const customFunction = ref();

async function onSaveCustomFunction() {
  addEventToLoadingQueue({ key: LOADING_KEY });

  try {
    const { addWorkNote } = useWorkNote();
    const { data, success } = await addWorkNote(customFunction.value);

    if (!success) {
      return;
    }

    customFunction.value = data;
    await CustomFunctions.update(customFunctionId.value, customFunction.value);

    toast.fire({
      icon: "success",
      title: i18n.t("customFunctions.saved")
    });
  } catch (e) {
    Error(e);
  } finally {
    removeEventFromLoadingQueue({ key: LOADING_KEY });
  }
}

function onOpenEditor() {
  router.push({
    name: "projectCustomFunctionsEditor",
    params: { id: customFunctionId.value }
  });
}

onMounted(async () => {
  if (!customFunctionId.value) {
    await router.push({ name: "projectCustomFunctions" });

    return;
  }

  const response = await CustomFunctions.get(customFunctionId.value);
  customFunction.value = response?.data;
});
</script>

<template>
  <div v-if="customFunction">
    <Header
      :title="
        $t('customFunctions.edit', { name: customFunction.name }).toString()
      "
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectCustomFunctions' })"
      @save="onSaveCustomFunction"
      @openEditor="onOpenEditor"
    />
    <div class="card card-custom">
      <div class="card-body">
        <Form ref="form" v-model="customFunction" :config="formConfig" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
